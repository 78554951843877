import React from "react"
import "./layout.css"
import { SearchBar } from "../styles/elements"
import SearchIcon from "../svg/search.svg"
import styled from "styled-components"

// searchIcon werkt neit meer sinds install react svg loader, en ook niet als component, maar de TMC image wel??????
// HOPPA iconstyle toegevoegd, en hij werkt (gekopieerd uit styles/elements)

const IconStyle = styled.div`
  position: absolute;
  top: 30%;
  left: 24px;
  transform: translateY() (-50%);
  z-index: 10;
  width: 16px;
  height: 16px;
`

const SearchBarComp = () => {
  return (
    <>
      <SearchBar>
        <IconStyle>
          <SearchIcon />
        </IconStyle>
        <input placeholder="Search your courses" />
      </SearchBar>
    </>
  )
}

export default SearchBarComp
