import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/Seo"

import SearchBar from "../components/SearchBar"

const SearchExample = () => (
  <Layout>
    <Link to="/">Go back to the homepage</Link>
    <Seo title="Page two" />
    <h1>Hi from the Search page</h1>
    <SearchBar />
  </Layout>
)

export default SearchExample
